<template>
    <v-container fluid class="mx-auto">
        <v-row class="mt-10">
            <v-col cols="12" offset-lg="9" lg="3" class="d-flex justify-lg-end justify-md-end">
                <v-dialog v-model="dialog" width="500">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn block large rounded v-bind="attrs" v-on="on">New Institution User</v-btn>
                    </template>

                    <v-card>
                        <v-card-title class="d-flex justify-center">Create New User</v-card-title>

                        <v-card-text>
                            <v-form>
                                <v-select rounded dense solo clearable :items="items" v-model="user.institution_code"
                                    label="Institution Name">
                                </v-select>
                                <v-text-field rounded dense solo v-model="user.firstname" clearable label="Firstname">
                                </v-text-field>
                                <v-text-field rounded dense solo v-model="user.lastname" clearable label="Lastname">
                                </v-text-field>
                                <v-text-field rounded dense solo v-model="user.telephone" clearable label="Téléphone">
                                </v-text-field>
                                <v-text-field rounded type="email" dense solo v-model="user.email" clearable
                                    label="Email">
                                </v-text-field>
                                <v-text-field @click:append="show = !show" v-model="user.password"
                                    :type="show ? 'text' : 'password'" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                    solo rounded placeholder="Password"></v-text-field>
                                <v-text-field type="number" min="0" max="1" rounded dense solo v-model="user.level"
                                     label="Niveau d'autorisation"></v-text-field>
                            </v-form>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-btn text color="success" :loading="loading" @click="create">Créer utilisateur</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="red" text @click="dialog = false">Annuler</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card rounded="xl">
                    <v-card-text>
                        <v-data-table :headers="headers" :items="users" :search="search">
                            <template v-slot:top>
                                <v-text-field solo rounded v-model="search" clearable label="Search an user name"
                                    class="mx-4 pt-4" append-icon="mdi-magnify"></v-text-field>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import { mapActions, mapState } from "vuex";

import services from "../services/services";


export default {
    name: "Users",
    data: () => ({
        dialog: false,
        search: "",
        loading: false,
        show: false,
        headers: [
            { text: "Firstname", value: "firstname", align: "center" },
            { text: "Lastname", value: "lastname", align: "center" },
            { text: "Email", value: "email", align: "center" },
            { text: "Telephone", value: "telephone", align: "center" },
            { text: "Institution Code", value: "institution_code", align: "center" },
            { text: "Niveau d'autorisation", value: "niveau", align: "center" },
        ],
        user: {
            firstname: "",
            lastname: "",
            telephone: "",
            email: "",
            level: "",
            password: "",
            institution_code: ""
        },
        items: [],
        users: []
    }),
    methods: {
        ...mapActions(['setInstitutions']),
        async create() {
            this.loading = true;

            for (let i = 0; i < this.institutions.length; i++) {

                if (this.user.institution_code === this.institutions[i].institution_name) {
                    this.user.institution_code = this.institutions[i].institution_code;
                    break;
                }
            }
            
            await services.create_institution_user(this.user)
            this.users = await services.get_institution_users();
            this.loading = false;
        }
    },
    computed: {
        ...mapState(['institutions'])
    },
    async mounted() {
        this.users = await services.get_institution_users();

        await this.setInstitutions();
        this.items = this.institutions.map(el => {
            return el.institution_name == null ? "" : el.institution_name
        });
        this.items.sort();
    }
}
</script>


<style scoped>
</style>